.animate-charcter {
    text-transform: capitalize;
    background-image: linear-gradient(-225deg,
            #00FF7F 0%,
            #44107a 33%,
            #ff1361 66%,
            #fbff00 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 10s linear infinite;
    display: inline-block;
    font-size: 65px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}