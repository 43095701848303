/* ReviewPage.css */

.review-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
  }
  
  .review-page-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .reviews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .review {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .rating {
    margin: 10px 0;
  }
  
  .star {
    color: #ccc;
    margin: 0 2px;
  }
  
  .star.filled {
    color: #f39c12;
  }
  
  /* You can customize colors, fonts, and other styles as needed */