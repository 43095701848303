.App {
    font-family: "Ubuntu", sans-serif;
    /* width: 100vw; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#light {
    background-color: #f3ebf6;
}

#dark {
    background-color: #46045e;
}

body {
    margin: 0;
    padding: 0;
}

body .main {
    width: 400px;
    height: 400px;

    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

#light .main {
    background-color: #fff;
}

#dark .main {
    background-color: #38004c;
}

.sign {
    padding-top: 40px;
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    font-size: 23px;
}

#light .sign {
    color: #8c55aa;
}

#dark .sign {
    color: #ffffff;
}

.username {
    width: 76%;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: "Ubuntu", sans-serif;
}

form.form1 {
    padding-top: 40px;
}

.password {
    width: 76%;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: "Ubuntu", sans-serif;
}

#light .username,
#light .password {
    color: rgb(38, 50, 56);
    background: rgba(136, 126, 126, 0.04);
}

#dark .username,
#dark .password {
    color: rgb(254, 255, 255);
    background: rgba(255, 247, 247, 0.299);
}

.username:focus,
.password:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.submit {
    cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background: linear-gradient(to right, #9c27b0, #e040fb);
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: "Ubuntu", sans-serif;
    margin-left: 35%;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #e1bee7;
    padding-top: 15px;
}

a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #e1bee7;
    text-decoration: none;
}

@media (max-width: 600px) {
    .main {
        border-radius: 0px;
    }
}

.switch {
    display: flex;
    width: 100vw;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.switch label {
    margin: 10px;
}

#light .switch label {
    color: black;
}

#dark .switch label {
    color: white;
}





/* AboutUs.css */

.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
  }
  
  .about-us-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-us-container p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* You can customize colors, fonts, and other styles as needed */